import { Link as GatsbyLink } from "gatsby-plugin-react-i18next";
import {
  LeadSection,
  Post,
  Link,
  FormInput,
  FormCheckbox,
  PostContentsContainer,
  Button,
} from "@lleed-partners/factor";
import { ArrowRight32 } from "@carbon/icons-react";
import { graphql } from "gatsby";
import { useI18next, Trans } from "gatsby-plugin-react-i18next";
import { Icon } from "../../fragments/Icon";
import { Layout } from "../../layouts";
import { Helmet } from "gatsby-plugin-react-i18next";

export default () => {
  const { t, language } = useI18next();

  return (
    <Layout
      navProps={{
        showSubNav: false,
      }}
    >
      <Helmet>
        <meta
          name="description"
          content={t("lleedpartners.contact-description-general-enquiry")}
        />
        <title>{t("General enquiry")}</title>
      </Helmet>
      <LeadSection
        intent="grey"
        padTop
        inline
        title={t("General enquiry")}
        subtitle={t(
          "If you would like to discuss some aspect of your business, get more information about our operations or for press purposes. We will get back to you in the promptest fasion."
        )}
        cta={
          <>
            <Link
              intent="ghost"
              as={GatsbyLink}
              to="/contact/"
              icon={<Icon icon={<ArrowRight32 />} />}
            >
              {t("Other contact options")}
            </Link>
          </>
        }
      />
      <form
        name="contact-general"
        method="post"
        data-netlify="true"
        netlify-honeypot="bot-field"
        action={`/${language}/contact/thank-you`}
      >
        <div style={{ display: "none" }}>
          <input type="hidden" name="form-name" value="contact-general" />
          <input name="bot-field" />
        </div>
        <Post>
          <h2>{t("About you")}</h2>
          <p>
            <Trans t={t}>
              Please give us more information about you and your company, so
              that we can best understand your request.
            </Trans>
          </p>
          <FormInput
            label={t("Company name")}
            placeholder={t("ACME")}
            name="company-name"
            required
          />
          <FormInput
            label={t("Contact name")}
            placeholder={t("John Doe")}
            name="contact-name"
            required
          />
          <FormInput
            label={t("Email")}
            placeholder={t("john.doe@acme.com")}
            name="contact-email"
            required
          />
          <FormInput
            label={t("Phone")}
            placeholder="+1 (716) 712-4110"
            name="contact-phone"
          />
          <FormCheckbox
            label={t("Contact preferences")}
            name="contact-prefers-email"
            defaultChecked="on"
          >
            {t("I prefer to be contacted by email")}
          </FormCheckbox>
          <FormCheckbox name="contact-prefers-phone">
            {t("I prefer to be contacted by phone")}
          </FormCheckbox>
          <h2>{t("Your message")}</h2>
          <p>
            <Trans t={t}>
              Let us know more about what you would like to discuss.
            </Trans>
          </p>
          <PostContentsContainer position="center">
            <FormInput
              as="textarea"
              label={t("Your message")}
              name="message"
              required
              defaultValue={t("...")}
              style={{ minHeight: "50vh" }}
            />
          </PostContentsContainer>
          <h2>{t("Consent options")}</h2>
          <FormCheckbox
            name="contact-consents"
            required
            label="Automated data treatment"
          >
            <Trans t={t}>
              I hereby allow Lleed & Partners to process the data in this form.
              <br />
              <small>
                Lleed & Partners will not distribute your data to third parties.
                Lleed & Partners will not use your data for marketing purposes.
              </small>
            </Trans>
          </FormCheckbox>
          <p />
          <Button
            as="button"
            type="submit"
            size="large"
            rightIcon={<Icon icon={<ArrowRight32 />} />}
          >
            {t("Submit this form")}
          </Button>
          <p />
        </Post>
      </form>
    </Layout>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
